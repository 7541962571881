.social{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    background: #141817;
    cursor: pointer;
}
.icons{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5rem;
}

@media screen and (max-width: 480px){
    .social{
       
        height: 100px;
        width: 100%;
    }
    .social .icons{
        transform: scale(0.5);
        gap: 1.3rem;
    }

}