.home{
    background-image:url('../../images/wallpaper.png') ;
    height: 600px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

@media screen and (max-width: 480px){
    .home{
        height: 150px;
        width: 100%;        
        background-size:100%;
    }
}