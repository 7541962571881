.services{
    display: flex;
    align-items: center;
    background: #000000;
    height: 450px;
    color: white;
    
    
}

.services-left{
  
    display: flex;
    flex-direction: column;
    margin-left: 200px;
    gap: 1rem;
    margin-right: 100px;
}



.services-small-title span{
    color: #5AC4A8;
    font-weight: 600;
    text-transform: uppercase;
    
}

.services-title h1{
    font-weight: 700;
    font-size: 55px;
}

.services-title span{
    font-weight: 700;
    font-size: 55px;
    color: #5AC4A8;
}

.services-txt{
    max-width: 400px;
}

.services-txt p{
    font-weight: 200 ;
}

.button a .btn{
    padding: 10px;
    border-radius: 10px;
    border: none;
    background-color: #5AC4A8;
    color: white;
    font-weight: 600;
    cursor: pointer;
}

.services-right{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding-bottom: 5px;   
    margin-right: 200px;
    
}

.services-cart-left{
    display: flex;
    flex-direction: column;
    padding-bottom: 60px;
   
}

.cart{
    padding: 10px;
}

.cart-txt{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1rem;
    background-color: #0B0B0B;
    height: 150px;
    width: 200px;
    padding: 10px;
    
}

.cart-txt h1{
    font-weight: bold;
}


.services-cart-left{
    display: flex;
    flex-direction: column;
    padding-bottom: 60px;
   
}


.services-cart-right{
    padding-bottom: 35px;
}


.services-cart-right>.cart>.cart-txt{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1rem;
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(14,44,38,1) 100%);
    height: 150px;
    width: 200px;
    padding: 10px;
}

.services-cart-right .cart .cart-txt h1{
    font-weight: bold;
}



@media screen and (max-width: 480px){
    .services{
        height: auto;
        width: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 40px;
    }
    .services-left{
        margin-left: 0;
        margin-right: 0;
        margin: 12px;
    }
    .services-right{
        flex-direction: column;
        margin-left: 12rem;
    }
    .services-title h1, .services-title span{
        font-size: xx-large;
    }
    .services-small-title span{
        font-size: small;
    }
    .services-txt p{
        font-size: small;
    }
    .button a .btn{
        font-size: small;
        width: 120px;    
    }
    .button{
        margin-left: -10px;
    }
    .cart-txt h1{
        font-size: medium;
    }
    .cart-txt span{
        font-size: small;
    }
   

}



































.services>.services-right{
    margin-right: 200px;
    margin-top: 70px;
}