.about{
    display: flex;
    color: white;
    background: #0C0C0C;
    height: 400px;
   
}

.about-left{
    margin-left: 200px;
    display: flex;
   flex-direction: column;
   justify-content: center;
}

.small-title span{
    color: #5AC4A8;
    font-weight: 600;
    text-transform: uppercase;
}

.about-title h1{
    font-size: 55px;
    font-weight: bold;
      
}

.about-title span{
    font-size: 55px;
    font-weight: bold;
    color:#5AC4A8;
      
}

.about-title span{
    font-size: 70px;
    font-weight: bold;
       
}


.about-right{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 200px;
    margin-left: 10rem;
   
}


.right-title{
    padding-bottom: 5px;
    line-height: 60px;
}

hr{
    width: 20%;
    border: 1px solid #5AC4A8;
}


.right-txt p{
    font-weight: 200;
   
}



@media screen and (max-width: 480px){
    .about{
        height: auto;
        width: auto;
        flex-direction: column;
        justify-content: center;
    }


    .about-right, .about-left{
        padding-top: 30px;
        margin-left: 0;
        margin-right: 0;
        margin: 12px;
    }
    .about-title h1, .about-title span{
        font-size: xx-large;
        line-height: 28px;
    }
    .small-title span{
        font-size: small;
    }
    .right-txt p{
        font-size: small;
        align-items: center;
    }
  
}