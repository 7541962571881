.contact{
    height: 500px;
    background: #000000;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-left{
    display: flex;
    flex-direction: column;
    margin-left: 200px;
    max-width: 400px;
    }

.contact-left  h1{
    font-size: 55px;
    font-weight: 600;
}

.contact-left>:nth-child(2){
    padding-top: 2rem;
}

.contact-left span{
    font-weight: 200;
}

.contact-right{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right: 200px;
}

form{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    margin-left: 5rem;
}

form input{
    padding: 10px;
    border-radius: 8px;
    cursor:pointer;
    width: 450px;
    outline: none;
    border: 3px solid #43BAB5;
}

form textarea{
    border-radius: 8px;
    cursor:pointer;
    width: 450px;
    padding: 10px;
    height: 100px;
    outline: none;
    border: 3px solid #43BAB5;
}

.contact-right .btn{
    padding: 12px 80px;
    border-radius: 10px;
    border: none;
    background-color: #5AC4A8;
    color: white;
    font-weight: bold;
    cursor: pointer;
      
}

.contact-form span{
    color:#5AC4A8;
    font-weight: 500;
}



@media screen and (max-width: 480px){

.contact{
    flex-direction: column;
    height: auto;
    width: 100%;
    padding-top: 30px;
}
.contact-left{
    margin-left: 0;
    padding-bottom: 40px;
    margin: 20px;
}
.contact-left h1{
    font-size: xx-large;
}
.contact-left span{
    font-size: small;
}
.contact-right{
    margin-right: 0;
}

form input, form textarea,.btn {
  width: 280px;
  transform: scale(0.8)
}
form{
    gap: 0.5rem;
    padding-bottom: 30px;
    margin-left: 0;
}

}