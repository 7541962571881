.portfolio{
    display: flex;
    height: 400px;
    background: #0C0C0C;
    color: white;
    
}

.portfolio-right{
        
        margin-right: 200px;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

.portfolio-right>span{
    color: #5AC4A8;
    font-weight: 600;
    text-transform: uppercase;
    padding-bottom: 1rem;
}

.portfolio-right>h1{
    font-weight: 700;
    font-size: 55px;
}

.portfolio-right>p{
    font-weight: 200;
    padding-top: 2rem;
    
}

.portfolio-left{
    flex: 1;
}


.portfolio-left{
    display: flex;
    margin-left: 200px;
    justify-content: center;
    align-items: center;
    margin-right: 11rem;

}

.portfolio-left>img{
    height: 300px;
}






@media screen and (max-width: 480px){

.portfolio{
    flex-direction: column;
    height: auto;
    width: auto;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
    padding-top: 30px;
}

.portfolio-left img{
    transform: scale(0.6);
  
}

.portfolio-right{
    margin-right: 0;
    margin: 20px;
}
.portfolio-right h1{
    font-size: xx-large;
}
.portfolio-right p{
    font-size: small;
}
.portfolio-right span{
    font-size: small;
}



}