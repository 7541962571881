.navbar{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 80px;
    width: 100%;
    background-color: #141414;
    color: white;
     
}

.nav-title img{
    transform: scale(0.8);
    margin-left: 130px;
    margin-right: 9rem;
    }

.nav-list ul{
    margin: 0 100px;
}

.nav-list li{
    display: inline-block;
    margin-right: 30px;
    font-weight: 600;
      
}

.nav-list li:hover{
    color: #5AC4A8;
    cursor:pointer;
}





@media screen and (max-width: 480px){
.navbar{
    height: auto;
    width: auto;
    padding-bottom:  20px ;
    flex-direction: column;
    justify-content: center;
}    

.nav-list li{
    flex-direction: column;
    display: inline;
    font-size: small;
    padding:0;
    margin: 5px;
}
.nav-list ul{
    margin: 0;
}
  

.nav-title{
    display: flex;
    gap: 5px;
}

.nav-title img{
    margin-left: 0;
    margin-right: 0;
    transform: scale(0.5);
}

   

}
